import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Sphere, Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import ThreeSixtyLobbyExtPic from "../../assets/360Pics/360-LOBBY-EXT.jpg";
import { useNavigate } from 'react-router-dom';

interface HotspotProps {
    position: [number, number, number];
    onClick: () => void;
    label: string;
}

const Hotspot: React.FC<HotspotProps> = ({ position, onClick, label }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = () => {
        setIsAnimating(true);
        setTimeout(() => {
            onClick();
        }, 500); // Match this with the animation duration
    };

    return (
        <Html position={position}>
            <button
                className={`bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200 transition duration-300 ${isAnimating ? 'hotspot-animation' : ''}`}
                onClick={handleClick}
            >
                {label}
            </button>
        </Html>
    );
};

const ThreeSixtyLobbyExt: React.FC = () => {
    const [currentImage, setCurrentImage] = useState<string>(ThreeSixtyLobbyExtPic);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const navigate = useNavigate(); // Use the useNavigate hook for navigation

    // Load the texture dynamically based on the current image state
    const texture = new THREE.TextureLoader().load(currentImage);

    const handleHotspotClick = (image: string, path: string) => {
        setIsTransitioning(true); // Start the zoom-in transition
        setCurrentImage(image);

        // Start the transition
        setTimeout(() => {
            navigate(path); // Navigate to the new page after the transition duration
        }, 500); // Match this with the transition duration
    };

    return (
        <div
            className={`w-full h-screen bg-black transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}
            style={{ position: 'relative' }}
        >
            <Canvas>
                <Sphere args={[500, 60, 40]} scale={[-1, 1, 1]}>
                    <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
                </Sphere>

                {/* Hotspots with navigation */}
                <Hotspot
                    position={[-770, 50, -50]}
                    label="POOL"
                    onClick={() => handleHotspotClick('/360-LGR-Pool-1', '/360-LGR-Pool-1')}
                />

                <Hotspot
                    position={[-170, -110, 550]}
                    label="LOBBY"
                    onClick={() => handleHotspotClick('/360-LGR-Lobby', '/360-LGR-Lobby')}
                />

                <Hotspot
                    position={[-370, -210, -150]}
                    label="SALES OFFICE"
                    onClick={() => handleHotspotClick('/360-LGR-Sales-Office', '/360-LGR-Sales-Office')}
                />

                <OrbitControls enableZoom={false} />
            </Canvas>
        </div>
    );
};

export default ThreeSixtyLobbyExt;
