import { Canvas, useThree } from "@react-three/fiber";
import { useState, useEffect, useRef } from "react";
import { TextureLoader, DoubleSide } from "three";
import { Sphere, OrbitControls } from "@react-three/drei";
import { OrbitControls as OrbitControlsImpl } from "three/examples/jsm/controls/OrbitControls";
import ThreeSixtyOption1 from "../../assets/360Pics/360-1.jpg"
import ThreeSixtyOption2 from "../../assets/360Pics/360-LGR-COFFEE-LOUNGE.jpg"
import ThreeSixtyOption3 from "../../assets/360Pics/360-LGR-POOL-1.jpg"

const scenes: Record<
  string,
  { image: string; lookAt: [number, number, number] }
> = {
  lobby: { image: ThreeSixtyOption1, lookAt: [1, 0, -1] },
  pool: { image: ThreeSixtyOption2, lookAt: [-1, 0, 2] },
  lounge: { image: ThreeSixtyOption3, lookAt: [2, 1, 3] },
};

export default function Tour() {
  const [texture, setTexture] = useState(new TextureLoader().load(scenes.lobby.image));
  const [cameraLookAt, setCameraLookAt] = useState<[number, number, number]>(scenes.lobby.lookAt);
  
  useEffect(() => {
    const loader = new TextureLoader();
    loader.load(scenes.lobby.image, (loadedTexture) => {
      setTexture(loadedTexture);
    });
  }, []);

  const changeScene = (sceneKey: keyof typeof scenes) => {
    const loader = new TextureLoader();
    loader.load(scenes[sceneKey].image, (loadedTexture) => {
      setTexture(loadedTexture);
      setCameraLookAt(scenes[sceneKey].lookAt);
    });
  };

  return (
    <Canvas>
      {/* 360 Sphere */}
      <Sphere args={[5, 60, 40]} scale={[-1, 1, 1]}>
        <meshBasicMaterial attach="material" map={texture} side={DoubleSide} />
      </Sphere>

      {/* Hotspots */}
      <mesh position={[1, 0, -3]} onClick={() => changeScene("pool")}>
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshBasicMaterial color="red" />
      </mesh>

      <mesh position={[-1, 0, -3]} onClick={() => changeScene("lounge")}>
        <sphereGeometry args={[0.1, 32, 32]} />
        <meshBasicMaterial color="blue" />
      </mesh>

      <CustomControls lookAt={cameraLookAt} />
    </Canvas>
  );
}

/* Custom OrbitControls Component */
function CustomControls({ lookAt }: { lookAt: [number, number, number] }) {
  const { camera } = useThree();
  const controlsRef = useRef<OrbitControlsImpl | null>(null);

  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.target.set(...lookAt);
      controlsRef.current.update(); //  Update camera target correctly
    }
  }, [lookAt]);

  return <OrbitControls ref={(ref) => (controlsRef.current = ref as OrbitControlsImpl | null)} />;
}
