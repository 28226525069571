import { Suspense } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SpinnerLoading from './Components/Spinner'
import { Amenities, Blog, BlogLanding, Contact, Feedbacksample, NewGallery2024, Gallery, Home, Journal, Management, Navbar, NotFound, Promos, Reservation, Room, SkyLounge, Spa, Phase1, Phase2, CoffeeLounge /*Zermatt*/ } from './Routes/LazyImports/Import'
import Footer from './Components/Footer'
import Feedback from './Pages/Feedback/Index'
import BlogAdmin from './Pages/Admin/Blog'
import Dashboard from './Pages/Admin/Dashboard'
import SmtpAdmin from './Pages/Admin/DashboardSuper'
import DriverAcc from './Pages/Admin/Driver'
import GalleryAdmin from './Pages/Admin/Gallery'
import JournalAdmin from './Pages/Admin/Journal'
import MaintenanceAcc from './Pages/Admin/Maintenance'
import PromoAdmin from './Pages/Admin/Promo'
import ReferalPortal from './Pages/Admin/Referral Portal/referralPortal'
import Login from './Pages/Auth/Login/Index'
import DriverNavbar from './Pages/Portals/Drivers Portal/DriverNav'
import DriverPortal from './Pages/Portals/Drivers Portal/DriverPortal'
import MaintenanceNavbar from './Pages/Portals/Maintenance/MaintenanceNav'
import MaintenancePortal from './Pages/Portals/Maintenance/MaintenancePortal'
import Referral from './Pages/Portals/Referral/Referral'
import Report from './Pages/Admin/Report'
import Register from './Pages/Auth/Resigter/Register'
import ReservationOnOff from './Pages/Admin/ReservationOnOff'
import ThreeSixtyLGRlgr from './360Page Options/360 Page Option 3/360-lgr-o3'
import ThreeSixtyLGRPool1 from './360Page Options/360 Page Option 1/360LGR-Pool1'
import ThreeSixtyLGRLobby from './360Page Options/360 Page Option 1/360-LGR-LOBBY'
import ThreeSixtyLGRSalesOffice from './360Page Options/360 Page Option 1/360-LGR-SALES-OFFICE'
import ThreeSixtyLGRCoffeeLounge from './360Page Options/360 Page Option 1/360-LGR-COFFEE-LOUNGE'
import ThreeSixtyLGRLobbyExt from './360Page Options/360 Page Option 1/360-LGR-LOBBY-EXT'

const currentURL = window.location.href;
function App() {
  return (
    <div className=' font-sans'>
    <BrowserRouter>
      <Suspense fallback={<SpinnerLoading/>}>
        <Routes>
        <Route path='/admin/login' element={<Login />} />
        {localStorage.getItem('token')? <>
        <Route path='/admin/blog' element={<BlogAdmin />} />
        <Route path='/admin/promo' element={<PromoAdmin />} />
        <Route path='/admin/journal' element={<JournalAdmin />} />
        <Route path='/admin/dashboard' element={<Dashboard />} />
        <Route path='/admin/portal/driver' element={<DriverAcc />} />
        <Route path='/admin/gallery' element={<GalleryAdmin />} /> 
        <Route path='/admin/smtp' element={<SmtpAdmin/>} />
        <Route path='/admin/portal/maintenance' element={<MaintenanceAcc />} />
        <Route path='/admin/report' element={<Report />} />
        <Route path='/admin/referral' element={<ReferalPortal />} />
        <Route path='/admin/register' element={<Register />} />
        <Route path='/admin/togglereservation' element={<ReservationOnOff />} />
        </>:<></>}
        <Route path='/portal/driver' element={<DriverNavbar />}>
        <Route index element={<DriverPortal />} />
        </Route>
        <Route path='/portal/maintenance' element={<MaintenanceNavbar />}>
        <Route index element={<MaintenancePortal />} />
        </Route>
        <Route path='/referral' element={<Referral/>}>
        
          
        </Route>
        {/* <Route path='/feedback' element={<Feedback/>}></Route> */}
        <Route path='/feedback' element={<Feedbacksample/>}></Route>

          <Route path='/' element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path='/journals' element={<Journal />} />
            <Route path='/promos' element={<Promos />} />
            <Route path='/Phase1' element={<Phase1/>} />
            <Route path='/Phase2' element={<Phase2/>} />
            <Route path='/room' element={<Room />} />
            <Route path='/amenities' element={<Amenities />} />
            {/*<Route path='/zermatt' element={<Zermatt />} />*/}
            <Route path='/skylounge' element={<SkyLounge />} />
            <Route path='/coffeelounge' element={<CoffeeLounge />} />
            <Route path='/spa' element={<Spa />} />
            <Route path='/blogs' element={<Blog />} />
            <Route path='/management' element={<Management />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/reservation' element={<Reservation />} />
            <Route path='/blog' element={<BlogLanding />} />
            <Route path='/gallery' element={<NewGallery2024 />} />
            <Route path='/360-LGR' element={<ThreeSixtyLGRlgr />} />
            <Route path='/360-LGR-Pool-1' element={<ThreeSixtyLGRPool1 />}/>
            <Route path='/360-LGR-Lobby' element={<ThreeSixtyLGRLobby />} />
            <Route path='/360-LGR-Sales-Office' element={<ThreeSixtyLGRSalesOffice />} />
            <Route path='/360-LGR-Coffee-Lounge' element={<ThreeSixtyLGRCoffeeLounge />} />
            <Route path='/360-LGR-Lobby-Ext' element={<ThreeSixtyLGRLobbyExt />} />
            <Route path='*' element={<NotFound />} />
            
          </Route>
          
          
        </Routes>
        {!currentURL.includes("admin") && !currentURL.includes("portal")&& !currentURL.includes("feedback") && !currentURL.includes("referral") ? <Footer />:<></>}
        
        
      </Suspense>
    </BrowserRouter>
  </div>
  )
}

export default App
